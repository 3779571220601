import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'digipos-restaurant-frontend';
  constructor(
    public sharedService: SharedService,
    private spinner: NgxSpinnerService
  ) {
    this.sharedService.isSpinnerShow.subscribe((isShow) => {
      if (isShow) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }
}
