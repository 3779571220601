import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private readonly baseUrl = environment.apiUrl;

  constructor( private http: HttpClient) {}

  getHeader() {
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders({
      Authorization: "Bearer" + " " + token,
    });
    return headers;
  }

  //#region API Calling Method With Header Authorization Token

  getWithToken(url: any) {
    return this.http.get(this.baseUrl + url, { headers: this.getHeader() });
  }

  getWithTokenWithParams(url: string, param: HttpParams) {
    return this.http.get(this.baseUrl + url, {
      headers: this.getHeader(),
      params: param,
    });
  }

  postWithToken(url: string, data: any) {
    return this.http.post(this.baseUrl + url, data, {
      headers: this.getHeader(),
    });
  }

  putWithToken(url: string, data: any) {
    return this.http.put(this.baseUrl + url, data, {
      headers: this.getHeader(),
    });
  }

  deleteWithToken(url: string) {
    return this.http.delete(this.baseUrl + url, { headers: this.getHeader() });
  }

  //#endregion

  //#region ResetPass

  resetPassword(url: string, data: any, header: any) {
    return this.http.post(this.baseUrl + url, data, {
      headers: header,
    });
  }

  //#region  API Calling Method Without Token

  get(url: string) {
    return this.http.get(this.baseUrl + url);
  }

  post(url: string, data: any) {
    return this.http.post(this.baseUrl + url, data);
  }
  //#endregion


  //#region Extra Function's

  public getParamsFromObj(obj): HttpParams {
    let params = new HttpParams();

    if (obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] !== "" && obj[key] !== undefined) {
          params = params.set(key, obj[key]);
        }
      });
    }
    return params;
  }

  //#endregion
}
