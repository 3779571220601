import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { LOCAL_STORAGE_KEYS } from '../global/constant';
import { IndexDbStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  isSpinnerShow = new BehaviorSubject<boolean>(false);
  isTableDataShow = new BehaviorSubject<boolean>(false);
  tableDataShow = this.isTableDataShow.asObservable();

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public modalService: NzModalService,
    private indexDbService: IndexDbStorageService
  ) {}

  showSpinner() {
    this.isSpinnerShow.next(true);
  }

  hideSpinner() {
    this.isSpinnerShow.next(false);
  }

  gotoPage(url: string) {
    this.router.navigateByUrl(url);
  }

  //#region Toastr Functions

  successToast(message: any, title?: any) {
    return this.toastr.success(message, title);
  }

  errorToast(message: any, title?: any) {
    return this.toastr.error(message, title);
  }

  warningToast(message: any, title?: any) {
    return this.toastr.warning(message, title);
  }

  infoToast(message: any, title?: any) {
    return this.toastr.info(message, title);
  }
  //#endregion

  getFormControls(formGroupName: FormGroup) {
    return formGroupName.controls;
  }

  onTableDataShow(data) {
    console.log(data, 'data');
    return this.isTableDataShow.next(data ? data : false);
  }

  get restaurantId() {
    return localStorage.getItem('restaurantId');
  }
}
