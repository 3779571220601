export const APIURL = {
  // Admin Auth Login
  adminAuthLogin: '/auth/login',

  // Outlet API End-Point
  outlet: '/outlet',

  // restaurant user role type API End-Point
  restaurantUserRoleType: '/restaurantUserRoleType',

  // restaurant user role type API End-Point
  restaurantUserRole: '/restaurant-user-role',

  // Category API End-Point
  category: '/category',

  // Sub Category API End-Point
  subCategory: '/sub-category',

  // Product API End-Point
  product: '/product',

  // Restaurant API End-Point
  restaurant: '/restaurant',

  // Pos Operator APi End-Point
  posOperator: '/pos-operator',

  sectionTypes:'/section-type',
  posTable: '/pos-tables',
  kitchenOperator: '/kitchen-operator',
};

export const LOCAL_STORAGE_KEYS = {
  TOKEN: 'token',
  USER_DETAILS: 'USER_DETAILS',
  RESTAURANT_DETAILS: 'RESTAURANT_DETAILS',
  SECTION_TYPE: 'SECTION_TYPE',
  POS_TABLES_LIST: 'POS_TABLES_LIST',
  SELECTED_SECTION: 'SELECTED_SECTION',
  ALL_TABLE_SECTION: 'ALL_TABLE',
};
